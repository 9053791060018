const BtnNavClose = ({setShowNav}) => {
  // RETURNING
  return (
    <button
    onClick={() => setShowNav(true)}
    className={`
      icon-bars 
      text-xl text-white
      focus:outline-none
      hover:text-bold
      inline-block self-end
      ani03s`
    }
    ></button>
  )
}
// Export
export default BtnNavClose
