import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
// Import Logo
import Logo from '../images/logo_unicef.svg'
// Import Components
import BtnNav from './BtnNav'
import BtnNavClose from './BtnNavClose'
import NavBar from './NavBar'
// Component
const Header = () => {
  // CONSTANTS
  const node = useRef();
  const [showNav, setShowNav] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowNav(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
    document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const location = useLocation();
    
  // RETURNING
  return (
    <header className="flex flex-col">
      <div className="
        flex flex-row justify-between
        text-sm items-center text-white
        bg-header_top_bg
        p-6 h-8">
        <div className="flex flex-row align">
            <Link to="/"><img className="max-h-6" src={Logo} alt="Unicef logo" /></Link>
            <span className="hidden xs:inline pl-4 align-baseline leading-6">vaccines</span>
        </div>
        <div className="office self-center md:hidden text-xs">
            Europe and Central Asia (ECA) Region
        </div>
        <div className="slogan2 text-lg xs:hidden text-right">
            for every child, <span className="font-bold">vaccines</span>
        </div>
      </div>
      <div className="flex flex-col flex-nowrap px-4 py-2 pt-2 pb-2 bg-main relative">
        {location.pathname === '/' ?
          <div className="h-full flex flex-col justify-center items-center p-1">
            <h1 className="font-light text-5xl lg:text-5xl md:text-4xl xs:text-3xl xxs:text-2xl text-white text-center">
                for every child, <span className="font-bold">vaccines</span>
            </h1>
          </div>
        :
          <div className="flex flex-row flex-nowrap justify-between">
            <div className="font-normal text-white text-xs flex-grow-0">
              <div className="font-bold text-lg">IMMUNIZATION</div>
              <div className="text-lg margin-top-less-5" style={{paddingBottom:4}}>E-bulletin</div>
            </div>
          </div>
        }
        <div className="absolute bottom-2 right-5">
          {showNav &&
            <BtnNav setShowNav = { setShowNav } />
          }
          {!showNav &&
            <BtnNavClose setShowNav = { setShowNav } />
          }
        </div>
      </div>
      
      <div ref={node} className="relative w-full h-0">
        { showNav && 
          <NavBar 
            showNav={ showNav }
            setShowNav={ setShowNav }
          /> 
        }
      </div>
    </header>
  )
}
// Export
export default Header
